import { useEffect } from "react";
const useAnalytics = (children) => {

    const isBrowser = typeof window !== "undefined"
    const href = isBrowser ? window.location.href : undefined;
    useEffect(() => {
        if(window?.OnetrustActiveGroups !== undefined){
            let pardotConsent = (window?.OnetrustActiveGroups?.indexOf('V1') !== -1 ) || ((window?.OnetrustActiveGroups?.indexOf('C0002') !== -1) && (window?.OnetrustActiveGroups?.indexOf('C0004') !== -1));
            window.pdt('hideConsentBanner', pardotConsent);
            if (pardotConsent){
                window?.pdt('sendPageView');
            }
        }
        function gtag() { window?.dataLayer?.push(arguments); }
        typeof gtag == 'function' && gtag('set', 'page_path', window?.location.pathname);
        typeof gtag == 'function' && gtag('event', 'page_view');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [href]);
    // // An example site function that returns the visitor opt in preference
    // // from a third party consent manager getConsentStatus();
    // let thirdPartyConsentStatus = getConsentStatus();

    // // Update Account Engagement analytics opt in status to match
    // window?.pdt('setOptIn', thirdPartyConsentStatus);
};
export default useAnalytics;
