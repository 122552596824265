import React from "react";
import { StaticQuery, graphql } from "gatsby";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";
import isMobileOS from "../util/isMobileOS";
import Modal from "../components/Modal";
import { LoaderProvider } from "../components/Loader";
import { AuthContextProvider } from "../components/auth-context";
import "../util/pardotAnalytics";
import useAnalytics from "../hooks/useAnalytics";

export const SiteContext = React.createContext({
  allMdx: [],
  site: [],
  isMobileOS: false,
  screenBreakpoints: {},
  modalShow: false,
  setModalShow: null,
  modalSrc: null,
  setModalSrc: null,
  modalHeight: 500,
  setModalHeight: null,
});

const screenBreakpoints = {
  xs: 500,
  sm: 660,
  md: 1080,
  lg: 1260,
  xl: 1400,
};


function Main({ children, pageTitle }) {
  const [windowSize, setWindowSize] = React.useState([]);
  const [currentBreakpoint, setCurrentBreakpoint] = React.useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalSrc, setModalSrc] = React.useState(false);
  const [modalHeight, setModalHeight] = React.useState(500);

  React.useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      function onWindowResize(event) {
        setWindowSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", onWindowResize);
      setWindowSize([window.innerWidth, window.innerHeight]);
      return () => {
        window.removeEventListener("resize", onWindowResize);
      };
    }
  }, []);

  React.useEffect(() => {
    const br_keys = Object.keys(screenBreakpoints);
    const br = br_keys.filter((bk) => screenBreakpoints[bk] >= windowSize[0]);
    setCurrentBreakpoint(br.length > 0 ? br[0] : br_keys[br_keys.length - 1]);
  }, [windowSize]);

  useAnalytics();

  return (
    <StaticQuery
      query={graphql`
        query {
          mdx: allMdx {
            edges {
              node {
                frontmatter {
                  slug
                  title
                }
              }
            }
          }
          site {
            siteMetadata {
              title
              siteUrl
              description
            }
          }
        }
      `}
      render={(data) => {
        return (
          <SiteContext.Provider
            value={{
              allMdx: data.mdx,
              site: data.site,
              isMobileOS: isMobileOS(),
              screenBreakpoints,
              windowSize,
              currentBreakpoint,
              modalShow,
              setModalShow,
              modalSrc,
              setModalSrc,
              modalHeight,
              setModalHeight,
            }}
          >
            <LoaderProvider>
              <AuthContextProvider>
                <div id="site-wrapper">
                  <SiteHeader />
                  <main id="site-body" role="main">
                    {children}
                  </main>
                  {modalShow && modalSrc && (
                    <Modal setModalShow={setModalShow} type="form">
                      <iframe src={modalSrc} frameBorder={0} height={modalHeight} title="main"/>
                    </Modal>
                  )}
                  <div className="push"></div>
                </div>
                <SiteFooter />
              </AuthContextProvider>
            </LoaderProvider>
          </SiteContext.Provider>
        );
      }}
    />
  );
}

export default Main;
