(function () {
    //https://developer.salesforce.com/docs/marketing/pardot/guide/tracking-and-consent-use-cases.html 
    //https://help.salesforce.com/s/articleView?id=sf.pardot_basics_cookies.htm&type=5 
    if (typeof window !== `undefined`) {
        var piAId = 868771;
        var piCId = 32647;
        var piDomain = process.env.PARDOT_DOMAIN;
        (function () {
            // The src value will now load pdt.js instead of pd.js
            var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = '//' + piDomain + '/pdt.js?aid=' + piAId;
            var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
            // Initialize the queue
            window['pdt'] = window['pdt'] || function () { (window['pdt'].cq = window['pdt'].cq || []).push(arguments); };
        })();
        window.pdt('create', piAId, piCId, piDomain);
        //supress the banner
        window.pdt('hideConsentBanner', null);
    }
})();
