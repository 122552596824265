import React from "react";

function Container({ size = "lg", className, children, ...props }) {
  return (
    <div className={`container size-${size} ${className || ""}`} {...props}>
      {children}
    </div>
  );
}

export default Container;
