import React, { createContext, useState } from "react";
import loading from "../images/spin-light.svg";
import darkLoading from "../images/spin-dark.svg";
export const Spinner = () => (
  <>
    <div className="overlay-loader-wrapper">
      <div className="overlay-loader">
        <div className="overlay-loader__content">
          <div className="overlay-loader__block">
            <div className="overlay-loader__block--inner">
              <span className="overlay-loader__line overlay-loader__line--first"></span>
              <span className="overlay-loader__line overlay-loader__line--second"></span>
              <span className="overlay-loader__line overlay-loader__line--last"></span>
            </div>
          </div>
          <span className="overlay-loader__text">Loading...</span>
        </div>
      </div>
    </div>
  </>
);

export const InlineSpinner = ({ theme }) => (
  <span className={`inline-spinner ${theme}`}>
    <img src={theme === "light" ? loading : darkLoading} alt="loading icon"/>
  </span>
);

export const LoaderContext = createContext({
  loading: false,
  showLoader: () => {},
  hideLoader: () => {},
});

export const LoaderProvider = ({ children }) => {
  const showLoader = () => {
    handleLoading((prev) => ({
      ...prev,
      loading: true,
    }));
  };
  const hideLoader = () => {
    handleLoading((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  const [loadingContext, handleLoading] = useState({
    loading: false,
    showLoader,
    hideLoader,
  });
  return (
    <LoaderContext.Provider value={loadingContext}>
      {loadingContext.loading ? <Spinner /> : null}
      {children}
    </LoaderContext.Provider>
  );
};
