import axios from "axios";
import { getAccessToken, setLocalStorage } from "./auth";
const apiClient = axios.create({
  baseURL: process.env.BASE_API_URL,
});
const onResponse = (response) => response;
const onResponseError = (error) => {
  if (error.response?.status === 401) {
    setLocalStorage({}, "authUser");
    console.log("Session expired. Please sign in again.");
    setTimeout(() => {
      window.location.replace("/app/login");
    }, 1000);
  }
  return Promise.reject(error);
};

function onRequest(config) {
  const token = getAccessToken();
  config.headers.Authorization = token || "";
  return config;
}
apiClient.interceptors.response.use(onResponse, onResponseError);
apiClient.interceptors.request.use(onRequest);

/** Api utils */
export const callLogout = () => {
  return apiClient.get("/logout").then((res) => res.data);
};

export const getFile = (fileName) => {
  return apiClient
    .post(`/get-files`, {
      files: [fileName],
    })
    .then((res) => res.data);
};
