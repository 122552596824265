/* eslint-disable import/prefer-default-export, react/prop-types */
import "../../src/styles/index.scss";
import React from "react";
import TopLayout from "./TopLayout";
import PageWrap from "./PageWrap";

export const wrapRootElement = (props) => {
  return <TopLayout>{props.element}</TopLayout>;
};

export const wrapPageElement = ({ element, props }) => {
  return <PageWrap element={element} props={props} data={props.data} />;
};
