import React from "react";
import {SiteContext} from '../layouts/Main'

function FormModal({
  src,
  buttonType = "solid",
  buttonLabel = "Launch Form",
  height = 550,
}) {

  const siteContext = React.useContext(SiteContext)

  React.useEffect(() => {
    if (siteContext.modalShow) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteContext.modalShow]);

  if (!src) return <></>;
  return (
    <>
      <button
        className={`button ${buttonType}`}
        onClick={(e) => {
          e.preventDefault();
          siteContext.setModalHeight(height);
          siteContext.setModalSrc(src);
          siteContext.setModalShow(true);
        }}
      >
        {buttonLabel}
      </button>
    </>
  );
}

export default FormModal;
