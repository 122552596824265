import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Baskervville&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
          rel="stylesheet"
        />
        <link href="/fontello/css/fontello.css" rel="stylesheet" />
        {/* Per google docs, run the gtag before consent */}
        <script src="/ga.js"/>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-10831064-1"/>
        {/* Onetrust cookie banner load */}
        {/* <script type="text/javascript" src="https://cdn.cookielaw.org/consent/d6a04018-46e1-478d-8c96-dc7ccb1eea78-test/OtAutoBlock.js" ></script> */}
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="d6a04018-46e1-478d-8c96-dc7ccb1eea78" ></script>
        <script type="text/javascript">{`function OptanonWrapper() { }`}</script>       

      </Helmet>
      {props.children}
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
