import React from "react";
import { Index } from "elasticlunr";
import { Link } from "gatsby";

function Search({ searchIndex, setSearchOpen }) {
  const [index, setIndex] = React.useState(null);
  const [searching, setSearching] = React.useState("");
  const [results, setResults] = React.useState([]);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    setIndex(Index.load(searchIndex));
    if (inputRef.current !== null) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (index !== null) {
      setResults(
        index
          .search(searching, { expand: true })
          .map(({ ref }) => index.documentStore.getDoc(ref))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searching]);

  return (
    <div id="search" action="/search">
      <input
        ref={inputRef}
        type="text"
        tabIndex="0"
        className="text-input"
        value={searching}
        onFocus={(e) => setSearching(e.target.value)}
        onChange={(e) => setSearching(e.target.value)}
        onKeyDown={(e) => setSearching(e.target.value)}
        placeholder="Search..."
      />
      <button
        title="Close Search"
        className="button link"
        tabIndex="0"
        onClick={(e) => {
          setSearchOpen(false);
        }}
      >
        <i className="icon-cancel"></i>
      </button>
      {results.length > 0 && (
        <ul>
          {results.map((page) => {
            return (
              <li key={page.id}>
                <Link tabIndex="0" to={page.slug} onClick={(e) => setSearchOpen(false)}>
                  {page.title}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
      {results.length === 0 && searching.length > 0 && (
        <ul>
          <li>
            <span>Nothing found</span>
          </li>
        </ul>
      )}
    </div>
  );
}

export default Search;
