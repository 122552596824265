import React from "react";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import Menu from "../components/Menu";

import footer_menus from "../data/footer-menus.json";

function distribute_menus(menus) {
  const cols = [];
  let i;
  let j;
  let chunk = 2;
  for (i = 0, j = menus.length; i < j; i += chunk) {
    cols.push(menus.slice(i, i + chunk));
  }
  return cols;
}

function SiteFooter() {
  const menu_groups = distribute_menus(footer_menus);
  const col_w = menu_groups.length > 2 ? 4 : 6;
  return (
    <>
    <footer id="site-footer">
      <b></b>
      <Container>
        <Row gutter="lg">
          {menu_groups.map((menus) =>
            menus.map((menu) => (
              <Column size={col_w} key={menu.title}>
                <Menu {...menu} />
              </Column>
            ))
          )}
        </Row>
      </Container>
    </footer>
    </>
  );
}

export default SiteFooter;
