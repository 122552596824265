import React from "react";
import { Link } from "gatsby";
import Modal from "./Modal";
import slugify from "../util/slugify";

function Menu({ title, ariaLabel, items, description, setMenuOpen }) {
  const attrs = ariaLabel ? { "aria-label": ariaLabel } : {};
  const [activeSubmenu, setActiveSubmenu] = React.useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [height, setHeight] = React.useState(500);
  return (
    <>
      <nav {...attrs}>
        {title && <strong>{title}</strong>}
        {description && <p dangerouslySetInnerHTML={{ __html: description }} />}
        {items && (
          <ul>
            {items.map(({ label, to, subitems, form, height = 500, target="_self" }) => {
              const item_id = `${slugify(label)}`;
              return (
                <li
                  key={item_id}
                  className={typeof subitems !== "undefined" ? "has-children" : ""}
                >
                  <Link
                    to={to}
                    onClick={(e) => {
                      if (form) {
                        e.preventDefault();
                        setHeight(height);
                        setModalShow(form);
                      }
                      if (typeof setMenuOpen !== "undefined") setMenuOpen(false);
                    }}
                    target={target}
                  >
                    {label}{" "}
                    {typeof subitems !== "undefined" && <i className="icon-down" />}
                  </Link>

                  {typeof subitems !== "undefined" && (
                    <>
                      <button
                        className={`button white ${
                          activeSubmenu === item_id ? "drop" : ""
                        }`}
                        onClick={(e) => {
                          if (activeSubmenu === item_id) {
                            setActiveSubmenu(null);
                          } else {
                            setActiveSubmenu(item_id);
                          }
                        }}
                      >
                        <i className="icon-down" />
                      </button>
                      <ul>
                        {subitems.map(({ label, to, form, height = 600, target = "_self" }) => (
                          <li key={`${label} ${to}  ${form}`}>
                            <Link
                              to={to}
                              target={target}
                              onClick={(e) => {
                                if (form) {
                                  e.preventDefault();
                                  setHeight(height);
                                  setModalShow(form);
                                }
                                if (typeof setMenuOpen !== "undefined")
                                  setMenuOpen(false);
                              }}
                            >
                              {label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </nav>
      {modalShow && (
        <Modal setModalShow={setModalShow} type="form">
          <iframe src={modalShow} frameBorder={0} height={height} title="Activation" />
        </Modal>
      )}
    </>
  );
}

export default Menu;
