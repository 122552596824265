import { isEmpty } from "lodash";

export const isBrowser = typeof window !== `undefined`;

export const getLocalStorage = (key) => {
  const localStorageContent = window.localStorage[key]
    ? JSON.parse(window.localStorage[key])
    : {};
  if (isEmpty(localStorageContent)) {
    return window.sessionStorage[key] ? JSON.parse(window.sessionStorage[key]) : {};
  }
  return localStorageContent;
};
export const setLocalStorage = (data, key, isSession) => {
  if (isSession) {
    window.sessionStorage[key] = JSON.stringify(data);
    return;
  }
  window.localStorage[key] = JSON.stringify(data);
};

export const isLoggedIn = () => {
  if (!isBrowser) return;
  const user = getLocalStorage("authUser");
  return !!(user?.token?.length && user.email);
};

export const getCurrentUser = () => isBrowser && getLocalStorage("authUser");
export const removeLocalStorage = (key) => isBrowser && localStorage.removeItem(key);

export const getAccessToken = () => {
  const user = getLocalStorage("authUser");
  return user?.token || "";
};

export const logout = () => {
  if (!isBrowser) return;
  localStorage.removeItem("authUser");
  sessionStorage.removeItem("authUser");
};
