import React from "react";

function Modal({ setModalShow, children, type = "video" }) {
  return (
    <div className={`modal type-${type}`}>
      <div>
        <div>
          <button
            className="button black"
            onClick={(e) => setModalShow(false)}
            id="close"
            aria-label="close"
          >
            <i className="icon-cancel" />
          </button>
          {children}
        </div>
      </div>
      <button onClick={(e) => setModalShow(false)}>
        <span></span>
      </button>
    </div>
  );
}

export default Modal;
