import React from "react";
import { Link, graphql, StaticQuery, navigate } from "gatsby";
import { SiteContext } from "../layouts/Main";
import Container from "./Container";
import Menu from "./Menu";
import Search from "./Search";
import FormModal from "./FormModal";

import logo from "../images/40th-logo.png";

import main_menu from "../data/main-menu.json";
import { callLogout } from "../util/http";
import { useAuth } from "./auth-context";

function SiteHeader() {
  const [user] = useAuth();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [signingOut, setIsSigningout] = React.useState(false);
  const siteContext = React.useContext(SiteContext);
  React.useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen]);

  React.useEffect(() => {
    if (searchOpen) {
      document.body.classList.add("search-open");
    } else {
      document.body.classList.remove("search-open");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOpen]);
  const handleLogout = () => {
    setIsSigningout(true);
    callLogout()
      .then(() => {
        setIsSigningout(false);
        // logout();
        navigate("/app/logout");
      })
      .catch((err) => {
        console.log(err);
        setIsSigningout(false);
        navigate("/app/logout");
        // logout();
      });
  };
  return (
    <StaticQuery
      query={graphql`
        query SearchIndexQuery {
          siteSearchIndex {
            index
          }
        }
      `}
      render={(data) => (
        <header id="site-header">
          <Container>
            <div className="l">
              <button
                id="burger"
                className="button"
                title="Toggle Menu"
                onClick={(e) => setMenuOpen(!menuOpen)}
              >
                <b />
                <b />
                <b />
              </button>
              <button id="menu-underlay" onClick={(e) => setMenuOpen(false)}>
                <span>Close menu</span>
              </button>
              <Link
                to="/"
                title="Go to home page"
                className="logo"
                onClick={(e) => setMenuOpen(false)}
              >
                <img src={logo} alt={siteContext.site.siteMetadata.title} />
              </Link>
              <Menu {...main_menu} setMenuOpen={setMenuOpen} />
            </div>
            <div className="r">
              {user?.token ? (
                <button
                  disabled={signingOut}
                  className="button outline"
                  onClick={handleLogout}
                >
                  {signingOut ? "Signing out..." : "Sign out"}
                </button>
              ) : (
                <Link
                  to="/app/login"
                  className="button outline"
                  onClick={(e) => {
                    setMenuOpen(false);
                  }}
                >
                  Sign in
                </Link>
              )}
              <FormModal
                src="https://ivorycc.my.salesforce-sites.com/ContactUsDemo"
                buttonLabel="Request Demo"
                buttonType="solid"
              />
              <button
                title="Search"
                className="button link"
                onClick={(e) => {
                  setMenuOpen(false);
                  setSearchOpen(true);
                }}
              >
                <i className="icon-search"></i>
              </button>
              {searchOpen && (
                <Search
                  searchIndex={data.siteSearchIndex.index}
                  setSearchOpen={setSearchOpen}
                />
              )}
            </div>
          </Container>
        </header>
      )}
    />
  );
}

export default SiteHeader;
