import React, { useContext, useEffect, useState } from "react";
import { getCurrentUser, logout } from "../util/auth";

export const AuthContext = React.createContext();

export const AuthContextProvider = ({ children }) => {
  const [state, setState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      setState(user);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
  }, []);

  const syncLogout = React.useCallback((e) => {
    if (e.key === "authUser" && e.oldValue && !e.newValue) {
      logout();
      setState(null);
    }
  }, []);
  // syncing auth state on different tabs
  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return () => window.removeEventListener("storage", syncLogout);
  }, [syncLogout]);

  return (
    <AuthContext.Provider value={[{ ...state, isLoading }, setState]}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
