import React from "react";
import Main from "../../src/layouts/Main";
import { Helmet } from "react-helmet";

function PageWrap({ element, data, props }) {
  let meta_data;
  if (data?.mdx?.frontmatter) {
    meta_data = [];
    const meta_title = data.mdx.frontmatter.meta_title
      ? data.mdx.frontmatter.meta_title
      : data.mdx.frontmatter.title;
    meta_data.push(
      <title key="seo-title">{meta_title}</title>,
      <meta key="seo-og-title" property="og:title" content={meta_title} />,
      <meta key="seo-tw-title" property="twitter:title" content={meta_title} />
    );

    const meta_description = data.mdx.frontmatter.meta_description
      ? data.mdx.frontmatter.meta_description
      : data.mdx.frontmatter.excerpt;

    if (meta_description) {
      meta_data.push(
        <meta key="seo-desc" name="description" content={meta_description}></meta>,
        <meta key="seo-og-desc" property="og:description" content={meta_description} />,
        <meta
          key="seo-tw-desc"
          property="twitter:description"
          content={meta_description}
        />
      );
    }

    if (data.mdx.frontmatter.meta_image) {
      meta_data.push(
        <meta
          key="seo-og-img"
          property="og:image"
          content={data.mdx.frontmatter.meta_image}
        />,
        <meta
          key="seo-tw-img"
          property="twitter:image"
          content={data.mdx.frontmatter.meta_image}
        />
      );
    } else {
      meta_data.push(
        <meta
          key="seo-og-img"
          property="og:image"
          content="/logo.png"
        />,
        <meta
          key="seo-tw-img"
          property="twitter:image"
          content="/logo.png"
        />
      );
    }
  }

  return (
    <>
      {meta_data && <Helmet>{meta_data}</Helmet>}
      <Main {...props}>{element}</Main>
    </>
  );
}

export default PageWrap;
